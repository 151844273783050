import React, { useState } from "react"
import "../components/styles/products.scss"

const c2 = "/2.jpg"
const c5 = "/5.jpg"
const c7 = "/7.jpg"
const c10 = "/10.jpg"
const c15 = "/15.jpg"
const c19 = "/19.jpg"
const c35 = "/35.jpg"
const c47 = "/47.jpg"
const c425 = "/425.jpg"

const d14 = "/14.jpg"
const d05 = "/05.jpg"

const commercial = { c2, c5, c7, c10, c15, c19, c35, c47, c425 }
const domestic = { d14, d05 }

export default function ProductCy() {
  const [productCom, setProductCom] = useState(commercial.c19)
  const [productDom, setProductDom] = useState(domestic.d14)

  return (
    <>
      <div className="products_heading">
        <div className="l3">Products</div>
        <h2>Cylinders</h2>
        <div
          className="l3"
          style={{ color: "#eeeeee", opacity: "0.5", marginTop: "10px" }}
        >
          See out catalogue of gas cylinders
        </div>
      </div>
      <div className="commercial">
        <div className="commercial_heading">
          <h3 className="h2" style={{ color: "#004e8e" }}>
            Commercial
          </h3>
          <div className="commercial_options">
            <button
              style={{
                backgroundColor: productCom === commercial.c2 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c2)}
              className="option_b"
            >
              2 KG
            </button>
            <button
              style={{
                backgroundColor: productCom === commercial.c5 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c5)}
              className="option_b"
            >
              5 KG FTL
            </button>
            <button
              style={{
                backgroundColor: productCom === commercial.c7 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c7)}
              className="option_b"
            >
              7 KG
            </button>
            <button
              style={{
                backgroundColor: productCom === commercial.c10 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c10)}
              className="option_b"
            >
              10 KG
            </button>
            <button
              style={{
                backgroundColor: productCom === commercial.c15 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c15)}
              className="option_b"
            >
              15 KG
            </button>
            <button
              style={{
                backgroundColor: productCom === commercial.c19 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c19)}
              className="option_b"
            >
              19 KG
            </button>
            <button
              style={{
                backgroundColor: productCom === commercial.c35 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c35)}
              className="option_b"
            >
              35 KG
            </button>
            <button
              style={{
                backgroundColor: productCom === commercial.c47 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c47)}
              className="option_b"
            >
              47.5 KG
            </button>
            <button
              style={{
                backgroundColor:
                  productCom === commercial.c425 ? "#004e8e" : "",
              }}
              onClick={() => setProductCom(commercial.c425)}
              className="option_b"
            >
              425 KG
            </button>
          </div>
        </div>
        <div className="commercial_image">
          <img src={productCom} alt="Logo" placeholder="none" />
        </div>
      </div>
      <div className="domestic">
        <div className="domestic_heading">
          <h3 className="h2" style={{ color: "#e00810" }}>
            Domestic
          </h3>
          <div className="domestic_options">
            <button
              style={{
                backgroundColor: productDom === domestic.d14 ? "#e00810" : "",
              }}
              onClick={() => setProductDom(domestic.d14)}
              className="option_r"
            >
              14.2 KG
            </button>
            <button
              style={{
                backgroundColor: productDom === domestic.d05 ? "#e00810" : "",
              }}
              onClick={() => setProductDom(domestic.d05)}
              className="option_r"
            >
              5 KG
            </button>
          </div>
        </div>
        <div className="domestic_image">
          <img src={productDom} alt="Logo" placeholder="none" />
        </div>
      </div>
    </>
  )
}
